import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [


  {
    id: 2,
    label: 'MENUITEMS.DASHBOARDS.TEXT',
    icon: 'bx-home-circle',
    link: '/dashboard',
  },
  {
    id: 10,
    label: 'Etablissement',
    icon: 'bxs-school',
    link: '/etablissement/list',
  },
  {
    id: 14,
    label: 'Controleur',
    icon: 'bx-user-pin',
    subItems: [
      {
        id: 14,
        label: 'Liste des controleurs',
        link: '/controleur/list-controleur',
        parentId: 11
      },
      {
        id: 15,
        label: 'Affectations',
        link: '/controleur/list-affectation',
        parentId: 11
      },]
  },

  // {
  //   id: 12,
  //   label: 'Rapports',
  //   icon: 'bx-file',
  //   link: '/dashboard',
  // },

];

